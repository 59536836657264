@font-face
{
  font-family: 'Andale Mono';
  src:
    url(/static/media/AndaleMono.522b1e46.woff2) format('woff2'),
    url(/static/media/AndaleMono.3a393023.woff) format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* html, body, div, a, p, h1 */
/* { */
/*   padding: 0; */
/*   margin: 0; */
/* } */

html, body, #root
{
  background-color: #191919;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

html
{
  font-size: 62.5%;
}

body
{
  font-family: 'Andale Mono';
  font-weight: normal;
  font-style: normal;
}

a, p
{
  font-size: 1.2rem;
}

a,
a:active,
a:visited
{
  color: #ccc;
  text-decoration: none;
  transition: 0.33s ease;
}

a:hover
{
  color: #fff;
  text-shadow: 1px 1px 5px rgba(255, 255, 255, 0.3);

  /* color: rgba(189, 187, 224, 0.8); */
}

a:hover:before, a:hover:after
{
  box-shadow: 1px 1px 5px rgba(255, 255, 255, 0.3);
}

h1
{
  /* color: #fefefe; */

  /* text-shadow: 1px 1px 20px rgba(0, 0, 0, 0.3); */

  background: rgba(128, 128, 128, 0.5);
  background-clip: text;
  -moz-background-clip: text;
  -webkit-background-clip: text;

  color: transparent;

  font-size: 2.4rem;
  line-height: 1.6em;

  text-shadow: 0px 0px 1px rgba(255,255,255,0.95);
}

p
{
  color: #fefefe;
}

/*
 *
 * Animations
 *
 */

/* FadeIn */

.animation-fade-in
{
  animation: admination-fade-in-keyframes linear 0.5s;
  -webkit-animation: admination-fade-in-keyframes linear 0.5s;
  -moz-animation: admination-fade-in-keyframes linear 0.5s;
  -o-animation: admination-fade-in-keyframes linear 0.5s;
  -ms-animation: admination-fade-in-keyframes linear 0.5s;
}

@keyframes admination-fade-in-keyframes
{
  0%
  {
    opacity:0;
  }
  100%
  {
    opacity:1;
  }
}

@-webkit-keyframes admination-fade-in-keyframes
{
  0%
  {
    opacity:0;
  }
  100%
  {
    opacity:1;
  }
}

/* FlickerIn */

.animation-flicker-in
{
  animation: animation-flicker-in-keyframes linear 1.0s;
  -webkit-animation: animation-flicker-in-keyframes linear 1.0s;
  -moz-animation: animation-flicker-in-keyframes linear 1.0s;
  -o-animation: animation-flicker-in-keyframes linear 1.0s;
  -ms-animation: animation-flicker-in-keyframes linear 1.0s;
}

@-webkit-keyframes animation-flicker-in-keyframes
{
  0%
  {
    opacity: 0;
  }
  20%
  {
    opacity: 1;
  }
  40%
  {
    opacity: 0;
  }
  55%
  {
    opacity: 1;
  }
  65%
  {
    opacity: 0;
  }
  75%
  {
    opacity: 1;
  }
  80%
  {
    opacity: 0;
  }
  85%
  {
    opacity: 1;
  }
  90%
  {
    opacity: 0;
  }
  100%
  {
    opacity:1;
  }
}

@keyframes animation-flicker-in-keyframes
{
  0%
  {
    opacity: 0;
  }
  20%
  {
    opacity: 1;
  }
  40%
  {
    opacity: 0;
  }
  55%
  {
    opacity: 1;
  }
  65%
  {
    opacity: 0;
  }
  75%
  {
    opacity: 1;
  }
  80%
  {
    opacity: 0;
  }
  85%
  {
    opacity: 1;
  }
  90%
  {
    opacity: 0;
  }
  100%
  {
    opacity:1;
  }
}

/* FlickerOut */

.animation-flicker-out
{
  animation: animation-flicker-out-keyframes linear 1.0s;
  -webkit-animation: animation-flicker-out-keyframes linear 1.0s;
  -moz-animation: animation-flicker-out-keyframes linear 1.0s;
  -o-animation: animation-flicker-out-keyframes linear 1.0s;
  -ms-animation: animation-flicker-out-keyframes linear 1.0s;
}

@-webkit-keyframes animation-flicker-out-keyframes
{
  0%
  {
    opacity: 0;
  }
  20%
  {
    opacity: 1;
  }
  40%
  {
    opacity: 0;
  }
  55%
  {
    opacity: 1;
  }
  65%
  {
    opacity: 0;
  }
  75%
  {
    opacity: 1;
  }
  80%
  {
    opacity: 0;
  }
  85%
  {
    opacity: 1;
  }
  90%
  {
    opacity: 0;
  }
  100%
  {
    opacity:1;
  }
}

@keyframes animation-flicker-out-keyframes
{
  0%
  {
    opacity: 0;
  }
  20%
  {
    opacity: 1;
  }
  40%
  {
    opacity: 0;
  }
  55%
  {
    opacity: 1;
  }
  65%
  {
    opacity: 0;
  }
  75%
  {
    opacity: 1;
  }
  80%
  {
    opacity: 0;
  }
  85%
  {
    opacity: 1;
  }
  90%
  {
    opacity: 0;
  }
  100%
  {
    opacity:1;
  }
}

/* Slide */

.animation-slide-enter,
.animation-slide-exit {
    transition: -webkit-transform 1000ms ease-out;
    transition: transform 1000ms ease-out;
    transition: transform 1000ms ease-out, -webkit-transform 1000ms ease-out;
  }

  .animation-slide-enter {
      position: absolute;
      -webkit-transform: translateX(100%);
              transform: translateX(100%);
    }

    .animation-slide-enter.animation-slide-enter-active {
        -webkit-transform: translateX(0%);
                transform: translateX(0%);
      }

      .animation-slide-exit {
        position: inherit;
            top: 0;
              left: 0;
                width: 100%;
                  -webkit-transform: translateX(0%);
                          transform: translateX(0%);
                }
.animation-slide-exit.animation-slide-exit-active {
                    -webkit-transform: translateX(-100%);
                            transform: translateX(-100%);
                  }


/* Underline */

.animation-underline
{
  position: relative;
  padding-bottom: 0.4em;
}

.animation-underline:before,
.animation-underline:after
{
  content: "";
  position: absolute;
  bottom: 1px;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #fefefe;
}

.animation-underline:before
{
  opacity: 0;
  -webkit-transform: translateY(0.5em);
          transform: translateY(0.5em);
  transition: opacity 0s, -webkit-transform 0s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: transform 0s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0s;
  transition: transform 0s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0s, -webkit-transform 0s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.animation-underline:after
{
  opacity: 0;
  -webkit-transform: translateY(0.25em);
          transform: translateY(0.25em);
  transition: opacity 0.2s, -webkit-transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0.2s;
  transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0.2s, -webkit-transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.animation-underline:hover:before,
.animation-underline:hover:after,
.animation-underline:focus:before,
.animation-underline:focus:after
{
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.animation-underline:hover:before,
.animation-underline:focus:before
{
  transition: opacity 0.2s, -webkit-transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0.2s;
  transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0.2s, -webkit-transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.animation-underline:hover:after,
.animation-underline:focus:after
{
  transition: opacity 0s 0.2s, -webkit-transform 0s 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: transform 0s 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0s 0.2s;
  transition: transform 0s 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0s 0.2s, -webkit-transform 0s 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

/* Underline Grow */

.animation-underline-grow
{
  position: relative;
}

.animation-underline-grow:after
{
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: #fff;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}

.animation-underline-grow:hover:after
{
  width: 100%; 
  left: 0; 
}

/* Underline Slide */

.animation-underline-slide
{
  display: inline-block;
  position: relative;
  color: #0087ca;
}

.animation-underline-slide:after
{
  content: '';
  position: absolute;
  width: 100%;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #0087ca;
  -webkit-transform-origin: bottom right;
          transform-origin: bottom right;
  transition: -webkit-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
}

.animation-underline-slide:hover:after {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
}


/*
 *
 * Background
 *
 */

.background
{
  position: absolute;
  z-index: 0;
}

.background.fullscreen
{
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.background .background-filter,
.background .background-image
{
  position: absolute;
  height: 100%;
  width: 100%;
}

.background .background-image
{
  z-index: 0;
}

.background .background-filter
{
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.125), rgba(0, 0, 0, 0.125));
  box-shadow: inset 0px 0px 15vmin 15vmin rgb(0 0 0 / 30%);

  z-index: 0;
}

.background-center
{
  background-position: 50% 50%;
}

.background-cover
{
  background-size: cover;
}

.attribution
{
  position: absolute;

  bottom: 2.5vh;
  left: 0;
  right: 0;
}

.attribution p
{
  color: rgba(255, 255, 255, 0.3);
  text-align: center;
}

.attribution p:first-child
{
  padding-bottom: 1em;
}


.container
{
  display: flex;

  flex-direction: column;

  align-items: center;
  -webkit-align-items: center;

  justify-content: center;
  -webkit-justify-content: center;

  position: relative;

  width: auto;
}

.container.centered
{
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.container.fullscreen
{
  height: 100%;
  width: 100%;
}

.container.ontop
{
  z-index: 9999;
}

.content
{
  overflow: hidden;
}

.content > .animated
{
  position: absolute;
}

.content > .wrapper
{
}

.content,
.content > .wrapper
{
  min-width: 100vw;
  min-height: 100vh;
}

.card
{
  background: unset;

  border-radius: 30px;
  border-width: 0px;

  box-shadow: 0px 1px 40px rgba(0, 0, 0, 0.05);

  text-align: center;

  padding: 30px;

  transition: ease 0.3s;

  height: auto;
}

.card a
{
  font-size: 1.4rem;
}

.card .background
{
  z-index: -1;
}

.card .background .background-filter
{
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);

  background: unset;
  box-shadow: unset;
}

.card .background .background-image
{
}

.card .card-section
{
  padding-bottom: 1.2rem;
  grid-gap: 0.4rem;
  gap: 0.4rem;
}

.card .card-section-footer
{
}

.card .card-section-footer a
{
  font-size: 1.2rem;
}

.card .gfx
{
  margin: 30px 0;
}

.gfx-mountain
{
  background: url(/static/media/gfx-mountain.26bcd990.png) no-repeat 50% 50%;
  background-size: cover;

  height: 78px;
  width: 150px;
}

/*
 *
 * Sections
 *
 */

/* Index */

.index { }
.index-background .background-image
{
  background: url(/static/media/background.ba63a2fe.jpg) no-repeat 50% 50%;
  background-size: cover;
}

/* Me */

.me { }
.me .section-social
{
  flex-direction: row;
  grid-gap: 1rem;
  gap: 1rem;
}

